.App {
  text-align: center;
  font-family: 'Concert One', cursive;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-header {

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
html, body #root {
  height: 100%;
  /* background-size: auto; */
}


body {
  background-image: url("https://images.pexels.com/photos/1118873/pexels-photo-1118873.jpeg?cs=srgb&dl=pexels-johannes-plenio-1118873.jpg&fm=jpg");
  height: auto;
  width: 100%;
    /* background-position: contain; */
  background-size: 100% 150%;
}

.box {
  opacity: 0.75;
  box-shadow: 15px 15px 10px rgb(0, 0, 0);
  max-width: 400px;
  margin: 0 auto;
}

button {
  box-shadow: 15px 15px 10px rgb(0, 0, 0);
}

.row {
  justify-content: center;
  margin: 15px 0;
}
.WIcon {
width: 50%;
height: auto;
display: block;
  margin-left: auto;
  margin-right: auto;

}

h3.capitalize {
  text-transform:capitalize;
}

.title {
  font-family: 'Concert One', cursive;
  color: white;
  text-shadow: 8px 8px 5px black;
}


.form {
  opacity: 0.75;
  box-shadow: 15px 15px 10px rgb(0, 0, 0);
}
 
.card-body {
  color: #7868e6;
  
  height: 300px;
}

.card-body h1 {
  font-size: 4rem;
  
}

h2 {
  font-size: 2rem;
}

.maxMin {
  font-size: 1.5rem;
}

hr {
  opacity: 0%;
}

.gap {
  border-top: 0.5px;
}

.or {
  color: white;
}

.mainPage {
  height: 100%;
}

footer {
  font-family: 'Concert One', cursive;
  text-shadow: 8px 8px 5px black;
  color: #fff;
  height: 80%;
  width: 100%;
  height: 2.5rem;
  text-align: center;
  bottom:0;
  line-height: 40px;
}

